<template>
  <div class="filter_select">
    <rq-select clearable :value="value" :options="options" @change="change" />
  </div>
</template>

<script>
export default {
  name: 'select',
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: null,
      itemText: 'name',
      itemValue: 'id',
      options: [],
    }
  },
  watch: {
    params: {
      handler() {
        this.setSelect()
      },
      deep: true,
    },
  },
  mounted() {
    this.setSelect()
  },
  methods: {
    setSelect() {
      if (this.params.options) {
        this.options = this.params.options
        this.itemText = this.params.selectText || 'name'
        this.itemValue = this.params.selectValue || 'id'
      }
      this.value =
        this.params.options.find((item) => item.id === this.params.value) ||
        null
    },
    change(value) {
      this.$emit('onChange', value.id)
    },
  },
}
</script>

<style lang="scss">
.ruqi-table-filter-select {
  background-color: #ffffff;
  overflow: hidden;
}
</style>
