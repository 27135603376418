<template>
  <div class="login_page">
    <div class="picture" />
    <div class="form_block">
      <h2>Login</h2>
      <rq-input
        v-model="login"
        :error="loginError"
        class="login_input"
        placeholder="Login"
        @input="loginInput"
      />
      <rq-input
        v-model="password"
        :error="passwordError"
        class="password_input"
        placeholder="Password"
        mode="password"
        type="password"
        @input="passwordInput"
      />
      <div class="btn_block">
        <rq-btn @click="onLogin" :loading="loading">Log in</rq-btn>
        <!-- <rq-btn type="text">Recover password</rq-btn> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getApiError } from '@/helpers.js'
import store from '@/store/index'

export default {
  data() {
    return {
      loading: false,
      login: '',
      password: '',
      loginError: false,
      passwordError: false,
    }
  },
  mounted() {
    document.addEventListener('keypress', this.onKeypress)
  },
  methods: {
    ...mapActions('notifications', ['showNotification']),
    ...mapActions('auth', ['login']),
    onLogin() {
      if (this.loading) return
      this.loginError = false
      this.passwordError = false
      this.$nextTick(async () => {
        if (this.validate()) {
          this.loading = true
          const response = await this.$axios.post('/auth/', {
            login: this.login,
            password: this.password,
          })
          if (response?.data?.success) {
            const token = response.data.data?.token
            store.dispatch('auth/login', token)
            this.$router.push('/')
          } else {
            this.showNotification({
              text: getApiError(response) || 'Ошибка авторизации',
            })
          }
          this.loading = false
        }
      })
    },
    onKeypress(e) {
      if (e.key === 'Enter') this.onLogin()
    },
    validate() {
      this.loginError = !this.login.length
      this.passwordError = !this.password.length
      return !this.loginError && !this.passwordError
    },
    loginInput() {
      this.loginError = false
    },
    passwordInput() {
      this.passwordError = false
    },
  },
  beforeUnmount() {
    document.removeEventListener('keypress', this.onKeypress)
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.login_page {
  background-color: $grey_dark;
  display: flex;
  border-radius: 15px;
  overflow: hidden;
  .picture {
    width: 140px;
    overflow: hidden;
    background: url('@/assets/img/ruqi2.jpg');
    background-size: cover;
  }
  .form_block {
    width: 340px;
    padding: 25px 35px 30px 35px;
    box-sizing: border-box;
    h2 {
      margin-bottom: 20px;
    }
  }
  .login_input {
    width: 100%;
    margin-bottom: 20px;
  }
  .password_input {
    width: 100%;
    margin-bottom: 25px;
  }
  .btn_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: '600px') {
    .picture {
      display: none;
    }
  }
}
</style>
