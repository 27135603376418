<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: 'Frontend',
}
</script>

<style type="scss">
.home {
  height: 6000px;
}
</style>
