<template>
  <button :class="[type]">
    <div class="slot" :class="{ loading }">
      <slot />
    </div>
    <loading
      v-if="loading"
      class="loading_bar"
      :color="loadingColor"
      :radius="9"
    ></loading>
  </button>
</template>

<script>
export default {
  name: 'rq-btn',
  props: {
    type: {
      type: String,
      default: 'contained',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingColor: {
      type: String,
      default: '#dbe6ff',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

button {
  position: relative;
  text-align: center;
  font-size: 18px;
  border: none;
  cursor: pointer;
  color: $grey_light;
  border-radius: 7px;
  transition: all 0.1s ease-in 0s;
  user-select: none;
}
.contained {
  padding: 12px 25px;
  background-color: $blue;
  &:hover {
    background-color: $blue_dark;
  }
}
.text {
  padding: 5px 0;
  background-color: unset;
  color: $grey;
  &:hover {
    color: $grey_light;
  }
}
.loading_bar {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -4px 0 0 -25px;
}
.slot {
  &.loading {
    visibility: hidden;
  }
}
</style>
