<template>
  <div :class="[{ error, shake }, variant]" class="select_container">
    <div class="select_section" @click="toggleSelect">
      <div class="left_icon_block">
        <div v-if="mode === 'search'" class="icon">
          <img src="@/assets/icon/search.svg" />
        </div>
      </div>
      <div class="select_wrap">
        {{ title }}
      </div>
      <div class="right_icon_block">
        <div
          v-if="clearable"
          class="icon clear_icon"
          @click="clear"
          :class="{ invisible: clearDisabled }"
        >
          <img src="@/assets/icon/x.svg" />
        </div>
        <div class="icon chevron" :class="{ isOpen }">
          <img src="@/assets/icon/chevron_down.svg" />
        </div>
      </div>
    </div>
    <div ref="list" class="list_container">
      <div
        v-for="item in options"
        class="list_item"
        @click="selectItem($event, item)"
        :key="item.id"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rq-select',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'angularWhite',
    },
    mode: {
      type: String,
      default: 'angularWhite',
    },
  },
  data() {
    return {
      list: null,
      currentValue: '',
      currentType: this.type,
      shake: false,
      isOpen: false,
    }
  },
  computed: {
    clearDisabled() {
      return !this.title?.length
    },
    title() {
      return this.currentValue?.name || ''
    },
  },
  mounted() {
    this.currentValue = this.value || null
    this.list = this.$refs.list
    document.body.appendChild(this.list)
  },
  watch: {
    error(val) {
      if (val) {
        this.shake = true
        setTimeout(() => {
          this.shake = false
        }, 260)
      }
    },
    value(val) {
      this.currentValue = val || null
    },
  },
  methods: {
    clear(e) {
      e.stopPropagation()
      if (!this.clearDisabled) {
        this.currentValue = ''
        this.closeSelect()
        this.$emit('change', null)
      }
    },
    selectItem(e, value) {
      e.preventDefault()
      e.stopPropagation()
      this.currentValue = value
      setTimeout(() => {
        this.closeSelect()
      }, 0)
      this.$emit('change', this.currentValue)
    },
    toggleSelect(e) {
      if (this.isOpen) this.closeSelect()
      else this.openSelect(e)
    },
    openSelect(e) {
      const size = e.currentTarget.getBoundingClientRect()
      const listSize = this.list.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const distanceBottom = windowHeight - size.bottom
      if (distanceBottom < 310)
        this.list.style.top = size.top - listSize.height - 5 + 'px'
      else this.list.style.top = size.top + 50 + 'px'
      this.list.style.left = size.left + 'px'
      this.list.style.width = size.width + 'px'
      this.list.style.display = 'block'
      this.list.id = 'select_list'
      this.isOpen = true
      setTimeout(() => {
        document.addEventListener('click', this.closeSelect)
      }, 0)
    },
    closeSelect() {
      this.list.style.left = '-9999px'
      this.isOpen = false
      document.removeEventListener('click', this.closeSelect)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
.select_container {
  position: relative;
  .select_section {
    display: flex;
    align-items: center;
    height: 44px;
    border: 1px solid white;
    background-color: white;
    color: $dark_main;
    border-radius: 7px;
    overflow: hidden;
    padding: 0 18px;
    cursor: pointer;
  }
  .select_wrap {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .left_icon_block {
    margin-left: -9px;
    margin-right: 6px;
    min-width: 3px;
    display: flex;
    align-items: center;
  }
  .right_icon_block {
    margin-right: -9px;
    margin-left: 6px;
    min-width: 3px;
    display: flex;
    align-items: center;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 30px;
    height: 100%;
    opacity: 0.7;
    transition: all 0.2s ease-in-out 0s;
    user-select: none;
    &:hover {
      opacity: 1;
    }
    &.invisible {
      visibility: hidden;
    }
  }

  .chevron.isOpen {
    transform: rotate(180deg);
  }

  .clear_icon {
    img {
      width: 18px;
    }
  }

  &.stylishGrey {
    .input_section {
      height: 36px;
      border: 0;
      background: $dark_main_light;
      transition: $transition_background;
      &:hover {
        background: $dark_main;
      }
      &:active {
        background: $dark_main;
      }
      border-radius: 20px;
    }
    input {
      color: white;
      padding: 9px 0;
      font-size: 16px;
    }
    .icon {
      display: flex;
      align-items: center;
      img {
        width: 20px;
      }
    }
    .clear_icon {
      img {
        width: 18px;
      }
    }
  }

  &.error {
    .input_section {
      border: 1px solid $red;
      background-color: $red_extra_light;
    }
  }
  &.shake {
    .input_section {
      animation: vertical-shaking 0.25s 1 ease-in-out;
    }
  }
}
.list_container {
  position: absolute;
  box-sizing: border-box;
  top: 50px;
  left: -9999px;
  background-color: white;
  width: 100%;
  max-height: 300px;
  min-height: 30px;
  border-radius: 7px;
  z-index: 50;
  padding: 3px;
  .list_item {
    color: $dark_main;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 7px;
    user-select: none;
    &:hover {
      background-color: $grey_light;
    }
  }
}
</style>
