<template>
  <div class="filter_range">
    <div>
      <div class="title">from</div>
      <rq-input v-model="from" @input="updateFiled" />
    </div>
    <div>
      <div class="title">to</div>
      <rq-input v-model="to" @input="updateFiled" />
    </div>
  </div>
</template>

<script>
import RqInput from '@/components/ui/RqInput.vue'
export default {
  components: { RqInput },
  name: 'Range',
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      from: null,
      to: null,
    }
  },
  mounted() {
    this.setField()
  },
  watch: {
    params: {
      handler() {
        this.setField()
      },
      deep: true,
    },
  },
  methods: {
    setField() {
      this.from = this.params?.value?.from || null
      this.to = this.params?.value?.to || null
    },
    updateFiled() {
      let value = {}
      if (this.from) value.from = this.from
      if (this.to) value.to = this.to
      if (!this.from?.length && !this.to?.length) {
        value = null
      }
      this.$emit('onChange', value)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
.filter_range {
  display: flex;
  gap: 20px;
  .title {
    font-size: 12px;
    margin: 0 0 4px 0;
    color: $slate_gray;
  }
}
</style>
