<template>
  <div class="error_popup_container">
    <div :class="{ visible: loading }" class="loading_container">
      <LoadingCirclesWater />
    </div>
    <div :class="{ visible: !loading }" class="content_container">
      <h2 class="error_header">Error {{ error.id }}</h2>
      <div class="error_container">
        <div class="header">Date</div>
        <div class="description">{{ error.date_log }}</div>
      </div>
      <div class="error_container">
        <div class="header">User agent</div>
        <div class="description">{{ error.http_user_agent }}</div>
      </div>
      <div class="error_container">
        <div class="header">X forwarded for</div>
        <div class="description">{{ error.http_x_forwarded_for }}</div>
      </div>
      <div class="error_container">
        <div class="header">Remote ip</div>
        <div class="description">{{ error.remote_ip }}</div>
      </div>
      <div class="error_container">
        <div class="header">HTTP version</div>
        <div class="description">{{ error.request_http_version }}</div>
      </div>
      <div class="error_container">
        <div class="header">Request id</div>
        <div class="description">{{ error.request_id }}</div>
      </div>
      <div class="error_container">
        <div class="header">Request body</div>
        <div class="description">
          <vue-json-pretty
            :showLine="false"
            :showDoubleQuotes="false"
            :deep="3"
            :data="error.request_body"
          ></vue-json-pretty>
        </div>
      </div>
      <div class="error_container">
        <div class="header">Request method</div>
        <div class="description">{{ error.request_method }}</div>
      </div>
      <div class="error_container">
        <div class="header">Request path</div>
        <div class="description">{{ error.request_path }}</div>
      </div>
      <div class="error_container">
        <div class="header">Response status</div>
        <div class="description">{{ error.response_status }}</div>
      </div>
      <div class="error_container">
        <div class="header">Uid log</div>
        <div class="description">{{ error.uid_log }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingCirclesWater from '@/components/ui/LoadingCirclesWater'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import '@/assets/css/vueJsonPretty.scss'

export default {
  name: 'ErrorPopup',
  components: { LoadingCirclesWater, VueJsonPretty },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      body: {
        status: 200,
        text: '',
        error: null,
      },
    }
  },
  mounted() {},
  watch: {},
  methods: {
    open() {
      this.$emit('open', this.item.id)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
.error_popup_container {
  .error_header {
    margin-bottom: 40px;
  }
  min-width: 800px;
  min-height: 70vh;
  position: relative;
  .loading_container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0s;
    &.visible {
      opacity: 1;
    }
  }
  .content_container {
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0s;
    &.visible {
      opacity: 1;
    }
  }
  .error_container {
    background-color: $dark_main;
    border-radius: 7px;
    text-align: left;
    padding: 10px 20px 8px 20px;
    margin-bottom: 10px;
    .header {
      vertical-align: top;
      display: inline-block;
      width: 150px;
      padding-right: 24px;
      color: $grey;
    }
    .description {
      vertical-align: top;
      display: inline-block;
    }
  }
  @media (max-width: 930px) {
    min-width: 500px;
  }
  @media (max-width: 610px) {
    min-width: 300px;
  }
}
</style>
