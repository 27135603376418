import axios from '@/plugins/axios'
import router from '@/router'

export const auth = {
  namespaced: true,

  actions: {
    login(_, token) {
      localStorage.setItem('authToken', token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },
    logout() {
      router.push('/login')
      localStorage.removeItem('authToken')
      delete axios.defaults.headers.common['Authorization']
    },
  },
}
