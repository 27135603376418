import axios from 'axios'
import store from '@/store/index'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

instance.interceptors.response.use(
  async (response) => {
    if (response?.data?.error?.[0]?.code === '1200002') {
      store.dispatch('auth/logout')
      await new Promise((resolve) => setTimeout(resolve, 200))
    }
    return response
  },
  (error) => {
    const status = error?.response?.status
    if (!status) {
      return error
    }
    if (status === 500) {
      store.dispatch('notifications/showNotification', {
        text: `Ошибка сервера. "${error?.response?.data?.message}"`,
      })
    } else if (status === 401 || status === 403) {
      store.dispatch('notifications/showNotification', {
        text: 'Отказано в доступе',
      })
    } else if (status === 422) {
      store.dispatch('notifications/showNotification', {
        text: 'Некорректно сформированный запрос',
      })
    }
    return error
  }
)

const token = localStorage.getItem('authToken')
if (token) instance.defaults.headers.common['Authorization'] = `Bearer ${token}`

export default instance
