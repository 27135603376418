<template>
  <div class="default_layout">
    <Transition mode="out-in">
      <div v-if="isMenuOpen" class="mobile_menu" @click="toggleMenu"></div>
    </Transition>
    <div :class="{ open: isMenuOpen }" class="mobile_sidebar">
      <div
        v-for="page in pages"
        :key="page.url"
        class="sidebar_item"
        :class="{ active: isActive(page.url) }"
        @click="onMobileLink(page.url)"
      >
        <div class="icon">
          <img :src="require(`@/${page.src}`)" />
        </div>
        <div class="title">{{ page.title }}</div>
      </div>
    </div>
    <nav class="navBar main_container">
      <img class="menu_btn" @click="toggleMenu" src="@/assets/icon/menu.svg" />
      <div class="user_block">
        <div class="user_name">Sudarev Aleksandr</div>
        <div class="user_picture" />
        <div class="rq_menu right">
          <div class="container">
            <div class="item" @click="logout">
              <div class="icon">
                <img src="@/assets/icon/exit.svg" />
              </div>
              Logout
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="layout_body">
      <div class="sidebar">
        <div
          v-for="page in pages"
          :key="page.url"
          class="sidebar_item"
          :class="{ active: isActive(page.url) }"
          @click="$router.push(page.url)"
        >
          <div class="icon">
            <img :src="require(`@/${page.src}`)" />
          </div>
          <div class="title">{{ page.title }}</div>
        </div>
      </div>
      <div class="content_block">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index'

export default {
  name: 'DefaultLayout',
  data() {
    return {
      isMenuOpen: false,
      pages: [
        { url: '/', title: 'Main', src: 'assets/icon/logo.svg' },
        {
          url: '/frontend',
          title: 'Frontend',
          src: 'assets/icon/monitor.svg',
        },
        { url: '/backend', title: 'Backend', src: 'assets/icon/server.svg' },
      ],
    }
  },
  methods: {
    logout() {
      store.dispatch('auth/logout')
    },
    isActive(url) {
      return url === this.$route.path
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    onMobileLink(url) {
      this.$router.push(url)
      this.toggleMenu()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.default_layout {
  background-color: $grey_dark;
  min-height: 100vh;
}
.mobile_menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(5px);
  z-index: 20;
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}
.mobile_sidebar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  background-color: $dark_main;
  padding-top: 15px;
  box-sizing: border-box;
  z-index: 30;
  transition: width 0.4s cubic-bezier(0, 0.55, 0.45, 1) 0s;
  .sidebar_item {
    opacity: 0;
    padding-left: 20px;
    transition: opacity 0.5s ease-in-out 0s;
  }
  &.open {
    width: 280px;
    .sidebar_item {
      opacity: 1;
    }
  }
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}
.navBar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 9px;
  background-color: $dark_main;
  z-index: 10;
}
.menu_btn {
  width: 20px;
  margin-top: -2px;
}

.user_block {
  display: flex;
  position: relative;
  align-items: center;
  .rq_menu {
    right: 0;
    top: 35px;
    min-width: 150px;
  }
  &:hover {
    cursor: default;
    .user_picture {
      opacity: 1;
    }
    .user_name {
      color: $grey_light;
    }
    .rq_menu {
      visibility: visible;
      opacity: 1;
    }
  }
}
.user_name {
  margin-right: 15px;
  color: $grey;
  transition: $transition_color;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user_picture {
  width: 35px;
  height: 35px;
  border-radius: 7px;
  overflow: hidden;
  opacity: 0.8;
  background: url('@/assets/img/user.jpg');
  background-size: cover;
  transition: $transition_opacity;
}
.layout_body {
  position: relative;
}
.content_block {
  margin-left: 71px;
  @media (max-width: 600px) {
    margin-left: 0;
  }
}
.sidebar {
  top: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-top: 7px;
  background-color: #242732;
  overflow: hidden;
  width: 71px;
  transition: width 0.3s ease-in 0s;
  z-index: 120;
  @media (max-width: 600px) {
    display: none;
  }
  .title {
    opacity: 0;
  }
  &:hover {
    width: 175px;
    .title {
      opacity: 1;
    }
  }
}
.sidebar_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 11px 15px;
  &:hover {
    .title {
      color: $grey_light;
    }
  }
  &.active .icon {
    opacity: 1;
  }
}
.icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  img {
    margin-top: -3px;
  }
}
.title {
  transition: $transition_opacity, $transition_color;
  color: $grey;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
