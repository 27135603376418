<template>
  <div class="universal_filter">
    <rounded-button
      :showClearIcon="isActive"
      :onClick="toggleFilter"
      @onClear.stop="onClearFields"
      leftIconSrc="assets/icon/filter.svg"
    >
      Filters
    </rounded-button>

    <div v-if="isOpened" class="filter_container" :class="[position]">
      <div v-click-outside="closeFilter">
        <div class="filter_header">
          <div class="header"></div>
          <div class="actions">
            <rq-btn type="text" @click.prevent="onClearFields">
              Clear all
            </rq-btn>
          </div>
        </div>
        <div ref="filterContent" class="filter_content scrollbar">
          <div
            v-for="(item, index) in selectedFields"
            class="accordion"
            :key="index"
            :class="[`index${index}`]"
          >
            <div
              class="accordion_header"
              @click="toggleAccordion($event, index)"
            >
              <div>{{ item.name }}</div>
              <img src="@/assets/icon/chevron_down_white.svg" />
            </div>
            <div class="accordion_body scrollbar">
              <div class="accordion_body_container">
                <component
                  :is="item.type"
                  :params="item"
                  @onChange="updateFiled($event, index)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="filter_footer">
          <rq-btn type="text" class="mr_24" @click="cancelFilter">
            Cancel
          </rq-btn>
          <rq-btn type="text" @click="applyFilter(false)">Apply</rq-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import list from './fields/List'
import select from './fields/Select'
import range from './fields/Range'

export default {
  name: 'UniversalFilter',
  components: { list, select, range },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: 'default',
    },
    page_uuid: {
      type: String,
      default: '',
    },
    autosave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: true,
      position: 'bottom',
      isOpened: false,
      panel: {},
      lastPanel: {},
      selectedFields: [],
      lastSelectedFields: [],
      searchText: '',
      isApplyFilter: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/user']
    },
    hasChanges() {
      const selectedFields = this.selectedFields.map((item) => item.value)
      const lastSelectedFields = this.lastSelectedFields.map(
        (item) => item.value
      )
      return (
        JSON.stringify(selectedFields) !== JSON.stringify(lastSelectedFields)
      )
    },
    isActive() {
      return this.lastSelectedFields.filter((item) => item.value).length > 0
    },
  },
  watch: {
    fields: {
      handler(val) {
        if (val.length) {
          this.setFields()
        }
      },
      deep: true,
    },
    isOpened(val) {
      if (val)
        this.$nextTick(() => {
          this.refreshAccordion()
        })
    },
  },
  created() {},
  mounted() {
    this.setFields()
    this.checkActiveFields()
  },
  methods: {
    checkActiveFields() {
      this.isApplyFilter =
        this.selectedFields.find((item) => item.value) !== undefined
    },

    setFields() {
      this.selectedFields = JSON.parse(JSON.stringify(this.fields))
      this.lastSelectedFields = JSON.parse(JSON.stringify(this.fields))
      this.checkActiveFields()
    },

    updateFiled(value, index) {
      this.selectedFields[index].value = value
    },

    toggleFilter(e) {
      const size = e.currentTarget.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const distanceBottom = windowHeight - size.bottom
      this.position = distanceBottom < 350 ? 'top' : 'bottom'
      this.isOpened = !this.isOpened
    },

    closeFilter() {
      this.isOpened = false
    },

    refreshAccordion() {
      const filterContent = this.$refs.filterContent
      for (let key in this.panel) {
        const accordion = filterContent.querySelector(key)
        const body = accordion.querySelector('.accordion_body')
        accordion.classList.add('opened')
        body.style.height = this.panel[key] + 'px'
      }
    },

    toggleAccordion(e, index) {
      const accordion = e.currentTarget.parentNode
      const body = accordion.querySelector('.accordion_body')
      accordion.classList.toggle('opened')
      let height = 0
      if (accordion.classList.contains('opened')) {
        height = accordion
          .querySelector('.accordion_body_container')
          .getBoundingClientRect().height
      }
      this.panel[`.index${index}`] = height
      body.style.height = height + 'px'
    },

    applyFilter(isClosePopup) {
      const sentFilter = {}
      this.selectedFields.forEach((item) => {
        if (item.value) {
          sentFilter[item.field] = {
            field: item.field,
            type: item.type,
            value: item.value,
          }
        }
      })
      this.lastPanel = JSON.parse(JSON.stringify(this.panel))
      this.lastSelectedFields = JSON.parse(JSON.stringify(this.selectedFields))
      this.$emit('applyFilter', sentFilter)
      this.isOpened = isClosePopup
      this.checkActiveFields()
    },

    onClearFields() {
      this.clearFields()
    },

    clearFields() {
      this.selectedFields.forEach((item) => (item.value = null))
      this.lastSelectedFields.forEach((item) => (item.value = null))

      this.$emit('applyFilter', {})
      this.checkActiveFields()
    },

    cancelFilter() {
      if (this.isApplyFilter) {
        this.selectedFields = JSON.parse(
          JSON.stringify(this.lastSelectedFields)
        )
        this.panel = JSON.parse(JSON.stringify(this.lastPanel))
      } else {
        this.panel = []
      }
      this.isOpened = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.universal_filter {
  position: relative;
  display: inline-block;
  z-index: 20;
  height: 36px;

  .filter_container {
    position: absolute;
    width: 330px;
    background-color: $dark_main;
    border-radius: 7px;
    top: 48px;
    left: 0;
    &.top {
      top: auto;
      bottom: 48px;
    }
  }
  .filter_header {
    display: flex;
    min-width: 250px;
    justify-content: space-between;
    padding: 14px 24px;
  }
  .filter_content {
    margin: 0 21px;
    padding: 0 3px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .filter_footer {
    display: flex;
    min-width: 250px;
    justify-content: flex-end;
    padding: 14px 24px;
  }
  .accordion.opened {
    .accordion_header img {
      transform: rotate(180deg);
    }
  }
  .accordion_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 40px;
    img {
      width: 18px;
      height: auto;
      transition: all 0.2s ease-in;
    }
  }
  .accordion_body {
    height: 0;
    max-height: 180px;
    overflow-y: auto;
    transition: height 0.2s ease-in;
  }
  .accordion_body_container {
    padding: 0 10px 10px 10px;
  }
}
</style>
