<template>
  <div class="login_layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.login_layout {
  background-color: $dark_main;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
