const qs = require('qs')

export const serialize = (params) => {
  return qs.stringify(params, {
    arrayFormat: 'brackets',
    encode: false,
    skipNulls: true,
    filter: (prefix, value) => {
      if (
        prefix !== '' &&
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      )
        return JSON.stringify(value)
      return value
    },
  })
}

export const getApiError = (response) => {
  return response?.data?.error?.[0]?.msg || response?.data?.error?.msg
}

export const debounce = (func, wait, immediate) => {
  let timeout
  return function () {
    const context = this,
      args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (immediate && !timeout) func.apply(context, args)
  }
}
