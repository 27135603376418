<template>
  <div class="filter_list">
    <label
      class="filter_label"
      v-for="(item, index) in params.options"
      :key="index"
    >
      <input
        type="checkbox"
        :checked="selected.includes(item.id)"
        @change="onCheckbox($event, item.id)"
      />
      {{ item.name }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'List',
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  mounted() {
    this.setField()
  },
  watch: {
    params: {
      handler() {
        this.setField()
      },
      deep: true,
    },
  },
  methods: {
    setField() {
      this.selected = this.params.value || []
    },
    onCheckbox(e, id) {
      if (e.target.checked) {
        this.selected.push(id)
      } else {
        this.selected = this.selected.filter((item) => item !== id)
      }
      this.$emit('onChange', this.selected.length ? this.selected : null)
    },
  },
}
</script>

<style lang="scss">
.filter_list {
  .filter_label {
    display: block;
    cursor: pointer;
    margin-left: -5px;
    padding: 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
