const directives = {
  'click-outside': {
    mounted: (el, binding) => {
      el.clickOutsideEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event)
        }
      }
      setTimeout(() => {
        document.body.addEventListener('click', el.clickOutsideEvent)
      }, 0)
    },
    unmounted: function (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  },
}
export default directives
