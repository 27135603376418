<template>
  <div :style="containerStyles" class="lds-ellipsis">
    <div :style="dotsStyles"></div>
    <div :style="dotsStyles"></div>
    <div :style="dotsStyles"></div>
    <div :style="dotsStyles"></div>
  </div>
</template>

<script>
export default {
  name: 'loading',
  props: {
    color: {
      type: String,
      default: '#dbe6ff',
    },
    radius: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {
      dotsStyles: {
        background: this.color,
        width: this.radius + 'px',
        height: this.radius + 'px',
      },
      containerStyles: {
        height: this.radius + 'px',
      },
    }
  },
  watch: {
    color(val) {
      this.dotsStyles.background = val
    },
    radius(val) {
      this.dotsStyles.width = val
      this.dotsStyles.height = val
      this.containerStyles.height = val
    },
  },
}
</script>

<style scope>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 12px;
}
.lds-ellipsis div {
  position: absolute;
  top: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 0px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 0px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 20px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 40px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(20px, 0);
  }
}
</style>
