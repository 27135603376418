import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/pages/Home.vue'
import Login from '@/pages/Login.vue'
import Frontend from '@/pages/Frontend.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'LoginLayout',
      noAuthRequired: true,
    },
  },
  {
    path: '/frontend',
    name: 'frontend',
    component: Frontend,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/backend',
    name: 'backend',
    component: Frontend,
    meta: {
      layout: 'DefaultLayout',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const noAuthRequired = to.matched.some((record) => record.meta.noAuthRequired)
  const isAuthenticated = localStorage.getItem('authToken')

  if (!noAuthRequired && !isAuthenticated) {
    next('/login')
  } else {
    next()
  }
})

export default router
