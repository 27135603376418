<template>
  <div class="home_page">
    <popup v-model:show.sync="popupVisible">
      <ErrorPopup :error="openedError" />
    </popup>
    <div class="chart_section main_container">
      <Chart :labels="labels" :dates="dates" />
    </div>
    <div class="filter_section main_container">
      <rq-date-picker class="date_picker" v-model:range="dateRange" />
      <universal-filter
        class="universal_filter"
        :fields="filters"
        @applyFilter="applyFilter"
      />
      <rq-input
        v-model="search"
        variant="stylishGrey"
        class="search"
        mode="search"
        clearable
        empty-is-null
      />
    </div>
    <div class="table_section main_container">
      <error-row
        v-for="item in items"
        :item="item"
        :key="item.id"
        @open="openError"
      />
      <lazy-load ref="lazyLoad" :callBack="loadMore" />
    </div>
    <div class="loadingWrap">
      <ThinLineLoading v-if="loading" />
    </div>
  </div>
</template>

<script>
import ThinLineLoading from '@/components/ui/LoadingThinLine.vue'
import ErrorPopup from '@/components/ErrorPopup'
import Chart from '@/components/Chart'
import { serialize, debounce } from '@/helpers.js'
import { getApiError } from '@/helpers.js'
import { mapActions } from 'vuex'

export default {
  name: 'HomeView',
  components: { ThinLineLoading, ErrorPopup, Chart },
  data() {
    return {
      popupVisible: false,
      loading: false,
      search: '',
      openedError: {},
      dateRange: null,
      counter: 0,
      allDataLoaded: false,
      filters: [
        {
          field: 'request_method',
          name: 'Method',
          type: 'select',
          value: null,
          options: [
            { id: 'POST', name: 'POST' },
            { id: 'GET', name: 'GET' },
            { id: 'PUT', name: 'PUT' },
            { id: 'PATCH', name: 'PATCH' },
            { id: 'DELETE', name: 'DELETE' },
          ],
        },
        {
          field: 'response_status',
          name: 'Status code',
          type: 'range',
          value: null,
        },
      ],
      page: 1,
      params: {
        per_page: 15,
        search: null,
        filters: {},
      },
      labels: [
        'January',
        'February',
        'March',
        'January',
        'February',
        'March',
        'January',
        'February',
        'March',
        'February',
        'March',
        'January',
        'February',
        'March',
      ],
      dates: [10, 20, 12, 32, 20, 12, 10, 20, 12, 20, 12, 42, 20, 45],
      items: [],
    }
  },
  watch: {
    popupVisible(val) {
      if (!val) this.closeError()
    },
    search(val) {
      this.updateSearchText(val)
    },
    dateRange(val) {
      if (val === null) {
        delete this.params.filters.start_date
      } else {
        const from = this.$dayjs(val[0]).format('YYYY-MM-DD')
        const to = this.$dayjs(val[1]).format('YYYY-MM-DD')
        this.params.filters.start_date = {
          field: 'date_log',
          type: 'range',
          value: { from, to },
        }
      }
    },
    params: {
      handler() {
        this.page = 1
        this.fetchErrors()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.query?.errorId > 0) {
      this.popupVisible = true
    }
    this.fetchErrors()
  },
  methods: {
    ...mapActions('notifications', ['showNotification']),
    openError(error) {
      this.openedError = error
      this.popupVisible = true
      this.$router.push({ query: { errorId: error.id } })
    },
    closeError() {
      this.openedError = null
      let query = Object.assign({}, this.$route.query)
      delete query.errorId
      this.$router.replace({ query })
    },
    loadMore() {
      if (this.loading || this.allDataLoaded) return
      this.page += 1
      this.counter += 1
      this.fetchErrors()
    },
    applyFilter(value) {
      this.filters.forEach((item) => {
        if (this.params.filters[item.field])
          delete this.params.filters[item.field]
      })
      for (let item in value) {
        this.params.filters[item] = value[item]
      }
    },
    getParams() {
      const params = Object.assign({}, this.params)
      params.page = this.page
      params.filters = JSON.parse(
        JSON.stringify(Object.values(this.params.filters))
      )
      return params
    },
    async fetchErrors() {
      if (this.loading) return
      this.loading = true
      const params = this.getParams()
      const concat = params.page === 1
      const response = await this.$axios.get('inputlogs/', {
        params,
        paramsSerializer: serialize,
      })
      if (response?.data?.success) {
        if (concat) this.items = response.data.data
        else this.items.push(...response.data.data)
        this.allDataLoaded = response.data.data.length < params.per_page
        this.loading = false // не перемещать, нужна для loadMore
        this.$refs.lazyLoad.checkObserver()
      } else {
        this.loading = false
        this.showNotification({
          text: getApiError(response) || 'Ошибка при получении списка ошибок',
        })
      }
    },
    updateSearchText: debounce(function (val) {
      this.params.search = val
    }, 400),
  },
}
</script>

<style lang="scss">
.home_page {
  .table_section {
    padding-bottom: 20px;
  }
  .filter_section {
    margin-bottom: 10px;
  }
  .date_picker,
  .universal_filter {
    vertical-align: top;
    margin-bottom: 10px;
    margin-right: 12px;
  }
  .search {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
  }
  .chart_section {
    padding-top: 30px;
    max-height: 50vh;
    margin-bottom: 40px;
  }
  .error_row_container {
    margin-bottom: 5px;
  }
  .loadingWrap {
    width: 100%;
    height: 4.5px;
    position: fixed;
    bottom: 0;
  }
  @media (max-width: 900px) {
    .error_row_container {
      margin-bottom: 20px;
    }
  }
}
</style>
