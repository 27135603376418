<template>
  <div class="rounded_button">
    <img
      v-if="leftIconSrc.length > 0"
      class="left_icon"
      :src="require(`@/${leftIconSrc}`)"
    />
    <span class="text"><slot /></span>
    <div v-if="showClearIcon" class="clear_icon" @click="clear">
      <img src="@/assets/icon/x_grey_light.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'rounded-button',
  props: {
    showClearIcon: {
      type: Boolean,
      default: false,
    },
    leftIconSrc: {
      type: String,
      default: '',
    },
  },
  computed: {},
  mounted() {},
  methods: {
    clear(e) {
      this.$emit('onClear', e)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
.rounded_button {
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 36px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  padding: 0 17px 0 14px;
  background: $dark_main_light;
  transition: $transition_background;
  user-select: none;
  &:hover {
    background: $dark_main;
  }
  .text {
    padding-top: 2px;
    padding-left: 7px;
  }
  .left_icon {
    width: 20px;
  }
  .clear_icon {
    width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
    margin: 0 -5px 0 6px;
    &:hover {
      opacity: 1;
    }
    img {
      width: 18px;
    }
  }
}
</style>
