<template>
  <div :class="[{ error, shake, focus }, variant]" class="input_container">
    <div class="input_section">
      <div class="left_icon_block">
        <div v-if="mode === 'search'" class="icon">
          <img src="@/assets/icon/search.svg" />
        </div>
      </div>
      <div class="input_wrap">
        <input
          ref="input"
          v-bind="$attrs"
          :value="modelValue"
          @input="updateInput"
          @change="change"
          :type="currentType"
        />
      </div>
      <div class="right_icon_block">
        <div
          v-if="clearable"
          class="icon clear_icon"
          @click="!clearDisabled && clear()"
          :class="{ invisible: clearDisabled }"
        >
          <img src="@/assets/icon/x_grey_light.svg" />
        </div>
        <div v-if="mode === 'password'" class="icon" @click="togglePassword">
          <img v-if="currentType === 'text'" src="@/assets/icon/eye_off.svg" />
          <img v-if="currentType === 'password'" src="@/assets/icon/eye.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rq-input',
  props: {
    modelValue: {
      type: [String, Number, null],
      required: true,
    },
    emptyIsNull: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    mode: {
      type: String,
      default: 'text',
    },
    error: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'angularWhite',
    },
  },
  data() {
    return {
      currentValue: '',
      currentType: this.type,
      shake: false,
    }
  },
  computed: {
    clearDisabled() {
      return !this.modelValue?.length
    },
    focus() {
      return true
    },
  },
  watch: {
    error(val) {
      if (val) {
        this.shake = true
        setTimeout(() => {
          this.shake = false
        }, 260)
      }
    },
  },
  methods: {
    updateInput(e) {
      const value =
        this.emptyIsNull && e.target.value === '' ? null : e.target.value
      this.$emit('update:modelValue', value)
      this.$emit('input', e)
    },
    clear() {
      const value = this.emptyIsNull ? null : ''
      this.$emit('update:modelValue', value)
      this.$refs.input.value = value
    },
    change(e) {
      const value =
        this.emptyIsNull && e.target.value === '' ? null : e.target.value
      this.$emit('update:modelValue', value)
      this.$emit('change', e)
    },
    togglePassword() {
      this.currentType = this.currentType === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
.input_container {
  .input_section {
    display: flex;
    height: 44px;
    border: 1px solid white;
    background-color: white;
    border-radius: 7px;
    overflow: hidden;
    padding: 0 18px;
  }
  .input_wrap {
    flex: 1;
  }
  input {
    display: inline-block;
    width: 100%;
    background: none;
    padding: 12px 0;
    color: $dark_main;
    font-size: 18px;
    box-sizing: border-box;
    border: none;
  }

  .left_icon_block {
    margin-left: -9px;
    margin-right: 6px;
    min-width: 3px;
    display: flex;
  }
  .right_icon_block {
    margin-right: -9px;
    margin-left: 6px;
    min-width: 3px;
    display: flex;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 30px;
    height: 100%;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out 0s;
    user-select: none;
    &:hover {
      opacity: 1;
    }
    &.invisible {
      visibility: hidden;
    }
  }

  .clear_icon {
    img {
      width: 18px;
    }
  }

  &.stylishGrey {
    .input_section {
      height: 36px;
      border: 0;
      background: $dark_main_light;
      transition: $transition_background;
      &:hover {
        background: $dark_main;
      }
      &:active {
        background: $dark_main;
      }
      border-radius: 20px;
    }
    input {
      color: white;
      padding: 9px 0;
      font-size: 16px;
    }
    .icon {
      img {
        width: 20px;
      }
    }
    .clear_icon {
      img {
        width: 18px;
      }
    }
  }

  &.error {
    .input_section {
      border: 1px solid $red;
      background-color: $red_extra_light;
    }
  }
  &.shake {
    .input_section {
      animation: vertical-shaking 0.25s 1 ease-in-out;
    }
  }
}
</style>
