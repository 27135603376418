<template>
  <div class="error_row_container">
    <div class="error_row_content" @click="open">
      <div class="info_block">
        <div class="status_icon">
          <img :src="require(`@/assets/icon/star.svg`)" />
        </div>
        <div class="date">
          {{ $dayjs(item.date_log).format('DD.MM.YY') }}
        </div>
        <div class="time">
          {{ $dayjs(item.date_log).format('HH:mm') }}
        </div>
        <div class="counter">
          <div class="label">{{ item.response_status }}</div>
        </div>
      </div>
      <div class="title">
        <div class="text_overflow">{{ item.http_referrer }}</div>
      </div>
      <div class="options_block">
        <div class="responsible">
          <div class="user_picture" />
        </div>
        <div class="options_btn" @click.stop>
          <img class="options_btn_img" src="@/assets/icon/dots.svg" />
          <div class="rq_menu right">
            <div class="container">
              <div class="item">Hidden</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="separator"></div>
  </div>
</template>

<script>
export default {
  name: 'error-row',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  methods: {
    open() {
      this.$emit('open', this.item)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
.error_row_container {
  .error_row_content {
    position: relative;
    padding: 15px 25px;
    border-radius: 7px;
    display: grid;
    cursor: pointer;
    grid-template-columns: 196px 1fr 90px;
    &:hover {
      background-color: $dark_main;
    }
    @media (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
      background-color: rgb(36 39 50 / 30%);
      .title {
        grid-column: 1 / 3;
        margin-top: 10px;
      }
      .options_block {
        grid-column: 2 / 2;
        grid-row: 1 / 1;
      }
    }
    @media (max-width: 600px) {
      padding: 15px 0px;
      background-color: initial;
      &:hover {
        background-color: initial;
      }
    }
  }
  .info_block {
    display: flex;
    align-items: center;
  }
  .status_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-right: 15px;
    overflow: hidden;
    img {
      width: 18px;
    }
  }
  .date,
  .time {
    color: $grey;
    white-space: nowrap;
  }
  .time {
    margin-right: 15px;
  }
  .date {
    margin-right: 8px;
  }
  .counter {
    width: 100px;
  }
  .label {
    padding: 3px 4px;
    background-color: $blue;
    display: inline-block;
    border-radius: 5px;
    font-size: 15px;
  }
  .title {
    display: flex;
    align-items: center;
  }
  .text_overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .options_block {
    display: flex;
    justify-content: right;
    align-items: center;
  }
  .user_picture {
    width: 25px;
    height: 25px;
    border-radius: 7px;
    overflow: hidden;
    background: url('@/assets/img/user.jpg');
    background-size: cover;
    margin-right: 15px;
  }
  .options_btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 7px;
    .rq_menu {
      min-width: 150px;
      right: -25px;
      bottom: 22px;
      padding-bottom: 20px;
      padding-top: 0;
      @media (max-width: 600px) {
        right: 0;
        bottom: 16px;
      }
    }
    &:hover {
      background-color: $grey_dark;
      .rq_menu {
        visibility: visible;
        opacity: 1;
      }
    }
    img {
      width: 4px;
    }
  }
  .separator {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.05);
    margin: 20px 0 0 0;
    display: none;
    @media (max-width: 600px) {
      display: block;
    }
  }
}
</style>
