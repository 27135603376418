<template>
  <div className="progress"></div>
</template>

<script>
export default {
  name: 'loadingThinLine',
  props: {
    color: {
      type: String,
      default: '#dbe6ff',
    },
  },
  watch: {},
}
</script>

<style lang="scss" scope>
@import '@/assets/css/variables.scss';

.progress {
  position: relative;
  height: 4.5px;
  width: 100%;
  background: linear-gradient($blue_dark 0 0), linear-gradient($blue_dark 0 0),
    $blue;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: progress-7x9cg2 2.5s infinite;
}

@keyframes progress-7x9cg2 {
  0% {
    background-position: -150% 0, -150% 0;
  }

  66% {
    background-position: 250% 0, -150% 0;
  }

  100% {
    background-position: 250% 0, 250% 0;
  }
}
</style>
