<template>
  <div class="calendar_container">
    <rounded-button
      :showClearIcon="showClearIcon"
      :onClick="openCalendar"
      @onClear="clear"
      leftIconSrc="assets/icon/calendar.svg"
    >
      {{ dateString }}
    </rounded-button>
    <input
      ref="input"
      class="calendar_input"
      id="rq-date-picker"
      type="text"
      readonly
    />
  </div>
</template>

<script>
import AirDatepicker from 'air-datepicker'
import 'air-datepicker/air-datepicker.css'
import 'air-datepicker/air-datepicker.css'
import RoundedButton from './RoundedButton.vue'

export default {
  components: { RoundedButton },
  name: 'rq-date-picker',
  props: {
    range: {
      type: Array,
      default: null,
    },
  },
  computed: {
    dateString() {
      if (this.range?.[1]) {
        const startDate = this.$dayjs(this.range[0])
        const format = startDate.isSame(new Date(), 'year')
          ? 'DD.MM'
          : 'DD.MM.YY'
        return (
          startDate.format(format) +
          ' - ' +
          this.$dayjs(this.range[1]).format(format)
        )
      }
      return 'Date'
    },
    showClearIcon() {
      return !!this.range?.[1]
    },
  },
  mounted() {
    const self = this
    this.dp = new AirDatepicker('#rq-date-picker', {
      range: true,
      position: 'top left',
      classes: 'rq_calendar',
      buttons: [
        {
          content: 'Clear',
          onClick: () => self.clear(),
        },
        {
          content: 'Apply',
          onClick(dp) {
            dp.hide()
            self.$emit('update:range', dp.selectedDates)
          },
        },
      ],
    })
  },
  methods: {
    clear(e) {
      e?.stopPropagation()
      this.dp.clear()
      if (this.dp.visible) this.dp.hide()
      this.$emit('update:range', null)
    },
    openCalendar() {
      this.dp.opts.position = this.definePosition()
      this.dp.show()
      this.$refs.input.focus()
    },
    definePosition() {
      const inputRect = this.$refs.input.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const distanceBottom = windowHeight - inputRect.bottom
      return distanceBottom < 310 ? 'top left' : 'bottom left'
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
@import '@/assets/css/airCalendar.scss';

.calendar_container {
  position: relative;
  display: inline-block;
  .calendar_input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 10px;
    height: 36px;
    cursor: default;
    padding: 0;
    margin: 0;
    border: 0;
  }
}
</style>
