import { createApp } from 'vue'
import App from '@/App.vue'
import components from '@/components'
import directives from '@/directives.js'
import axios from '@/plugins/axios'
import dayjs from '@/plugins/dayjs'
import router from '@/router'
import store from '@/store'

const app = createApp(App)

components.forEach((component) => {
  app.component(component.name, component)
})
for (let name in directives) {
  app.directive(name, directives[name])
}

app.config.globalProperties.$axios = axios
app.config.globalProperties.$dayjs = dayjs

app.use(store).use(router).mount('#app')
