import { createStore } from 'vuex'
import { notifications } from './notifications.js'
import { auth } from './auth.js'

const store = createStore({
  modules: {
    notifications: notifications,
    auth: auth,
  },
})

export default store
