<template>
  <div
    class="notification_container"
    :class="[notification.type, { visible: notification.isVisible }]"
  >
    <div @click="onHide">{{ notification.text }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Notifications',
  props: ['requestSuccess'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters('notifications', ['notification']),
  },
  methods: {
    onHide() {
      this.$store.commit('notifications/removeNotification')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
.notification_container {
  display: none;
  position: fixed;
  width: 100%;
  min-height: 42px;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 28px;
  color: white;
  background-color: $blue;
  z-index: 400;
  &.visible {
    display: block;
  }
  &.error {
    background-color: $red;
  }
  &.success {
    background-color: $green;
  }
}
</style>
