<template>
  <component :is="layout">
    <router-view />
    <Notifications />
  </component>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout'
import LoginLayout from '@/layouts/LoginLayout'
import Notifications from '@/components/ui/Notifications.vue'

export default {
  components: {
    DefaultLayout,
    LoginLayout,
    Notifications,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'LoginLayout'
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/global.scss';
@import '@/assets/css/reset.scss';
@import '@/assets/css/variables.scss';
html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  color: $grey_light;
  font-size: 17px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  padding: 0;
}
</style>
