<template>
  <Transition mode="out-in">
    <div v-if="!!show" id="popup_shadow" @click="hideFromShadow">
      <div id="popup_wrap">
        <div class="popup">
          <div class="popup-cross" @click="hidePopup">
            <img src="@/assets/icon/x_grey_light.svg" />
          </div>
          <div class="content scrollbar">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'popup',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  methods: {
    hidePopup() {
      this.$emit('update:show', false)
    },
    hideFromShadow(event) {
      if (
        event.target?.id === 'popup_wrap' ||
        event.target?.id === 'popup_shadow'
      ) {
        this.hidePopup()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

#popup_shadow {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #24293c75;
  position: fixed;
  display: flex;
  z-index: 220;
  align-items: center;
  backdrop-filter: blur(5px);
}
.popup-cross {
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
  color: red;
}
#popup_wrap {
  flex: 1;
  text-align: center;
}
.popup {
  position: relative;
  margin: 0 auto;
  max-height: 95vh;
  max-width: 95vw;
  min-width: 300px;
  width: max-content;
  box-sizing: border-box;
  background-color: $grey_dark;
  border-radius: 15px;
  padding: 45px 30px;
  @media (max-width: 600px) {
    max-width: 97vw;
    max-height: 97vh;
  }
}
.content {
  overflow: auto;
  max-height: calc(95vh - 90px);
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
